import React from 'react';
import Projects from './Projects';
import imgproject1 from "../Assets/Escoline Digital.png";
import imgproject2 from "../Assets/ElegeMais.png";
import imgproject3 from "../Assets/Consultech.png";
import imgproject4 from "../Assets/Desafio 21 Dias.png";
import imgproject5 from "../Assets/Vincc1 Eduk.png";

function Work() {
  return (
    <div id='features'>
        <h1>SOLUÇÕES</h1>
        <div className='a-container'>
            <a href="https://escolinedigital.com.br" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject1} title="Escoline Digital" subtitle="Sistema multiplataforma, que integra todo o sistema educacional para um gerenciamento completo." />
            </a>
            <a href="http://elegemais.com.br" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject2} title="Elege Mais" subtitle="Plataforma completa de serviços para treinamento e assessoria de candidatos políticos." />
            </a>
            <a href="https://wa.me/559981242197" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject3} title="Consultech" subtitle="Nosso serviço de consultoria em Tecnologia da Informação oferece soluções personalizadas e estratégicas para impulsionar o desempenho tecnológico da sua empresa." />
            </a>
            <a href="https://www.instagram.com/nutrigeyzebrito" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject4} title="Desafio 21 Dias" subtitle="Desafio de 21 dias focado no emagrecimento com tarefas diárias, plano alimentar e acompanhamento nutricional." />
            </a>
            <a href="https://wa.me/559981242197" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject5} title="Vincc1 Eduk" subtitle="Nossa escola de Tecnologia da Informação, fornece cursos e treinamentos de maneira presencial e on-line, através de uma abordagem prática e inovadora." />
            </a>
        </div>
    </div>
  )
}

export default Work