import React from 'react'


function Contact () {
  return (
    <div id="presentaion">
        
<div className='containerForm'>
  
  <div className='right'>
  <div className='title'><h1>CONTATO</h1></div>
    <div className='formBox'>
     
      <form action='https://formsubmit.co/contato@elegemais.com.br' method='POST'>
        <p>Digite seu nome</p>
        <input type="text" name="Nome" placeholder="Digite seu nome.." required></input>
        <p>Digite seu email</p>
        <input type="email" name="Email" placeholder="Digite seu email.." required></input>
        <p>Digite seu telefone</p>
        <input type="tel" name="Telefone" placeholder="(00)0 0000 0000" required></input>
        <p>Escreva sua mensagem</p>
        <input type="text" name="Mensagem" placeholder="Digite sua mensagem..." required></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_next" value="https://vincc1.com.br/"></input>
        <input type="submit" name="" value="Enviar"></input>
        <a href="https://wa.me/559981242197" target="_blank" rel="noreferrer"><input type="button" name=""  value="WhatsApp"></input></a>
      </form>
    </div>
  </div>


</div>

        

    </div>
  )
}
export default Contact