import React, { useState, useEffect, useRef, useCallback } from "react";
import fundo1 from "../Assets/home01.jpeg";
import fundo2 from "../Assets/home02.jpeg";
import { Link } from "react-scroll";

function Home() {
  const [count, setCount] = useState(1);
  const [text, setText] = useState({ title: "CONHEÇA A <span>VINCC1</span>", details: "Tecnologia e Inovação" });
  const totalImages = 2;
  const intervalId = useRef(null);

  const nextImage = useCallback(() => {
    setCount((prevCount) => (prevCount >= totalImages ? 1 : prevCount + 1));
  }, [totalImages]);

  const startAutoSlide = useCallback(() => {
    intervalId.current = setInterval(() => {
      nextImage();
    }, 5000);
  }, [nextImage]);

  useEffect(() => {
    startAutoSlide();
    return () => clearInterval(intervalId.current);
  }, [startAutoSlide]);

  useEffect(() => {
    switch (count) {
      case 1:
        setText({ title: "CONHEÇA A <span>VINCC1</span>", details: "Tecnologia e Inovação" });
        break;
      case 2:
        setText({ title: "DESCUBRA NOSSAS <span>SOLUÇÕES</span>", details: "" });
        break;
      default:
        setText({ title: "CONHEÇA A <span>VINCC1</span>", details: "Tecnologia e Inovação" });
    }
  }, [count]);


  const manualChange = (index) => {
    clearInterval(intervalId.current);
    setCount(index);
    startAutoSlide();
  };

  return (
    <div id="main">
      <div className="name">
      <h2 dangerouslySetInnerHTML={{ __html: text.title }}></h2>
        
          
        
      {text.details && <p className="details">{text.details}</p>}
      </div>
      <div className="slider">
        <div className="slides">
          <input type="radio" name="radio-btn" id="radio1" checked={count === 1} readOnly />
          <input type="radio" name="radio-btn" id="radio2" checked={count === 2} readOnly />
          

          <div className="slide first">
            <img src={fundo1} alt="s" />
          </div>
          
            <div className="slide">
            <Link to="features" smooth={true} duration={1000}>
              <img src={fundo2} alt="Imagem 2" />
            </Link>
            </div>
          
          

          <div className="navigation-auto">
            <div className="auto-btn1"></div>
            <div className="auto-btn2"></div>
            
          </div>
        </div>
      </div>

      <div className="manual-navigation">
        <label htmlFor="radio1" className="manual-btn" onClick={() => manualChange(1)}></label>
        <label htmlFor="radio2" className="manual-btn" onClick={() => manualChange(2)}></label>
        
      </div>
    </div>
  );
}

export default Home;
